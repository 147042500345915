<template>
  <AContainer class="b-news is-fluid pl-0 pr-0">
    <ASectionTitle>
      <template v-slot:icon>
        <img
          src="../../assets/icones/info.svg"
          alt="Ícone Conteúdo BemPerto®"
        />
      </template>
      <template v-slot:title>
        <!-- Conteúdo BemPerto® -->
        <span
          style="color: #70CDED; display: block; font-weight: 600; margin-left: -10px;"
        >
          Notícias
        </span>
      </template>
    </ASectionTitle>
    <ONewsSlider />
  </AContainer>
</template>

<script>
import AContainer from "@/components/atoms/Container.vue";
import ASectionTitle from "@/components/molecules/SectionTitle.vue";
import ONewsSlider from "@/components/organisms/NewsSlider.vue";

export default {
  components: {
    AContainer,
    ASectionTitle,
    ONewsSlider
  },
  name: "ONews"
};
</script>

<style lang="scss" scoped>
.b-news {
  margin-top: 4rem;
}
</style>
