<template>
  <AContainer
    class="b-medicines is-fluid pl-0 pr-0 pb-5"
    id="participating-programs"
  >
    <ASectionTitle>
      <template v-slot:icon>
        <img
          src="../../assets/icones/checkmark.svg"
          alt="Ícone programas participantes"
        />
      </template>
      <template v-slot:title>
        Áreas de atuação
      </template>
    </ASectionTitle>
    <OMedicinesSlider />
  </AContainer>
</template>

<script>
import AContainer from "@/components/atoms/Container.vue";
import ASectionTitle from "@/components/molecules/SectionTitle.vue";
import OMedicinesSlider from "@/components/organisms/MedicinesSlider.vue";

export default {
  components: {
    AContainer,
    ASectionTitle,
    OMedicinesSlider
  },
  name: "OMedicines"
};
</script>

<style lang="scss">
.b-medicines {
  margin: 3rem auto 4rem;

  .m-section-title__title {
    color: #70cded;
  }

  @include media(">=tablet") {
    margin-top: 6rem;
  }

  @include media(">=desktop") {
    margin-bottom: -120px;
    margin-top: 6rem;
    z-index: 2;
  }

  @include media(">=widescreen") {
    margin-bottom: -150px;
    margin-top: 7rem;
  }

  @include media(">=1600px") {
    margin-bottom: -200px;
  }
}

.b-medicines {
  color: #70cded !important;
}
</style>
