<template>
  <a id="back2Top" @click="() => backToTop()">
    <img src="../../assets/enter.svg" alt="Voltar para o topo da página" />
  </a>
  <a
    v-if="!isMobile()"
    target="_blank"
    href="https://wa.me/558001030420"
    id="backTopWhats"
    class="showWhats"
  >
    <img src="../../assets/whatsapp.svg" alt="Voltar para o topo da página" />
  </a>
</template>

<script>
export default {
  name: "BackToTop",
  props: {
    component: {
      default: null,
      required: false,
      type: String
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    handleScroll() {
      // console.log("aquiiiiiiiiiiiiiii teste123");
      const back2TopButton = document.getElementById("back2Top");
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        back2TopButton.classList.add("show");
      } else {
        back2TopButton.classList.remove("show");
      }
    }
  },
  setup() {
    function backToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
    return {
      backToTop
    };
  }
};
</script>

<style lang="scss">
@include media(">=768px") {
  #back2Top {
    position: fixed;
    bottom: 40px;
    right: 70px;
    width: 60px;
    height: 60px;
    background: transparent
      radial-gradient(closest-side at 41% -11%, #4baae6 0%, #298ece 100%) 0% 0%
      no-repeat padding-box;
    border-radius: 60px;
    border: none;
    cursor: pointer;
    overflow: hidden;
    opacity: 0;
    transition: all 0.6s ease-in-out;
    z-index: -1;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;
      height: 35px;
      transform: rotate(270deg);
    }
  }

  #back2Top.show {
    opacity: 1;
    z-index: 9999;
  }
}

@include media(">=768px") {
  #backTopWhats {
    position: fixed;
    bottom: 4px;
    right: -30px;
    width: 120px;
    height: 120px;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
    z-index: 9999;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;
      height: 100%;
    }
  }

  #backTopWhats.showWhats {
    opacity: 1;
    z-index: 9999;
  }
}

@include media("<768px") {
  #backTopWhats {
    position: fixed;
    bottom: 4px;
    right: -30px;
    width: 120px;
    height: 120px;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
    z-index: 9999;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;
      height: 100%;
    }
  }
}
// @include media("<768px") {
//   #backTopWhats {
//     display: none;
//   }
// }
</style>
