<template>
  <nav class="m-nav">
    <div class="m-nav__desktop-wrapper">
      <AColumns class="m-nav__desktop is-gapless m-nav__desktop--open" tag="ul">
        <AColumn class="is-narrow" tag="li">
          <router-link
            class="is-flex is-align-items-center mr-2 pl-2 pr-5 color4-navs"
            to="/"
            title="Home Programa BemPerto"
          >
            <img
              src="../../assets/house.svg"
              alt="Ícone Home Programa BemPerto"
            />
          </router-link>
        </AColumn>
        <AColumn class="is-narrow" tag="li">
          <router-link
            class="is-flex is-align-items-center mr-3 pl-2 pr-5 color2-navs"
            to="/fale-conosco"
            title="Fale Conosco"
          >
            <img src="../../assets/phone.svg" alt="Ícone fale conosco" />
            <span class="is-inline-block"> Fale <br />Conosco </span>
          </router-link>
        </AColumn>
        <AColumn class="is-narrow" tag="li">
          <router-link
            class="is-flex is-align-items-center mr-3 pl-2 pr-5 color3-navs"
            to="/sobre-o-programa"
            title="Saiba mais sobre o programa"
          >
            <img src="../../assets/heart.svg" alt="Ícone sobre o programa" />
            <span class="is-inline-block">
              Sobre o <br />
              Programa
            </span>
          </router-link>
        </AColumn>
        <AColumn tag="li">
          <a
            :href="`${host}/cadastro-novo`"
            class="is-flex is-align-items-center pl-2 colors6-navs"
            title="Cadastre-se"
          >
            <img src="../../assets/register.svg" alt="Ícone cadastre-se" />
            <span class="is-inline-block">
              Cadastre-se
            </span>
          </a>
        </AColumn>
        <AColumn class="is-narrow" tag="li">
          <a
            :href="`${host}/login`"
            class="is-flex is-align-items-center pl-2 pr-5 colors7-navs"
            title="Login"
          >
            <img src="../../assets/enter.svg" alt="Ícone acesse seu perfil" />
            <span class="is-inline-block">
              Entre
            </span>
          </a>
        </AColumn>
      </AColumns>
    </div>
    <div :class="{ 'm-nav__mobile--open': mobileOpen }" class="m-nav__mobile">
      <AColumns class="is-centered is-gapless is-mobile is-multiline">
        <AColumn class="has-text-centered is-full">
          <div class="m-nav__mobile__container is-inline-block">
            <router-link to="/" title="Programa BemPerto">
              <ALogo logoType="simple" />
            </router-link>
            <AColumns
              :class="{ 'is-hidden': !mobileOpen }"
              class="m-nav__mobile__items is-centered is-gapless is-mobile is-multiline mb-2 mt-4"
              tag="ul"
            >
              <AColumn class="has-text-left is-10-mobile is-8-tablet" tag="li">
                <router-link to="/" title="Home Programa BemPerto">
                  <img
                    src="../../assets/home-bemperto.svg"
                    alt="Ícone Home Programa BemPerto"
                  />
                  Home BemPerto®
                </router-link>
              </AColumn>
              <AColumn class="has-text-left is-10-mobile is-8-tablet" tag="li">
                <router-link
                  to="/sobre-o-programa"
                  title="Toque aqui e saiba mais sobre o Programa BemPerto"
                >
                  <img src="../../assets/half-heart.svg" alt="Ícone coração" />
                  Sobre o programa
                </router-link>
              </AColumn>
              <AColumn
                v-if="!isUserLoggedIn"
                class="has-text-left is-10-mobile is-8-tablet"
                tag="li"
              >
                <a
                  :href="`${host}/login`"
                  title="Toque aqui se já for participante do programa"
                >
                  <img src="../../assets/smile.svg" alt="Ícone sorriso" />
                  Entre
                </a>
              </AColumn>
              <AColumn
                v-if="!isUserLoggedIn"
                class="has-text-left is-10-mobile is-8-tablet"
                tag="li"
              >
                <a
                  :href="`${host}/cadastro-novo`"
                  title="Toque aqui e faça parte do Programa BemPerto"
                >
                  <img src="../../assets/plus.svg" alt="Ícone mais" />
                  Cadastre-se
                </a>
              </AColumn>
              <AColumn
                v-if="isUserLoggedIn"
                class="has-text-left is-10-mobile is-8-tablet"
                tag="li"
              >
                <a
                  @click="() => logout()"
                  :href="`#`"
                  title="Toque aqui e faça parte do Programa BemPerto"
                >
                  <img src="../../assets/plus.svg" alt="Ícone mais" />
                  Sair
                </a>
              </AColumn>
              <AColumn class="is-5-mobile is-4-tablet" tag="li">
                <a
                  :href="`${host}/politica`"
                  title="Toque aqui para ler nossa política de privacidade"
                >
                  Política de privacidade
                </a>
              </AColumn>
              <AColumn class="is-4-mobile is-3-tablet" tag="li">
                <a
                  :href="`${host}/termos-de-uso`"
                  title="Toque aqui para ler nossos termos de uso"
                >
                  Termos de uso
                </a>
              </AColumn>
            </AColumns>

            <div
              :class="{ 'link-mobile-open': mobileOpen }"
              class="link-mobile"
            >
              <div tag="div" class="bol">
                <div class="hedsetIcon-white" alt="Ícone fale conosco"></div>
                <router-link
                  style="border: none !important; color: white; font-size: 12px !important; text-transform: uppercase !important;"
                  class="textFaleConosco"
                  title="Fale Conosco"
                  to="/fale-conosco"
                >
                  Fale Conosco
                </router-link>
              </div>
            </div>

            <!-- <MContact :class="{ 'is-hidden': !mobileOpen }" class="has-text-centered mb-5" whiteIcon /> -->
            <a
              @click.prevent="toggleMobMenu"
              class="m-nav__mobile__action"
              href="#"
              title="Menu"
            >
              <img src="../../assets/close.svg" alt="Ícone fechar" />
              <img src="../../assets/hamburger.svg" alt="Ícone menu" />
            </a>
          </div>
        </AColumn>
        <AColumn class="amgen-logo has-text-centered is-full">
          <img src="../../assets/amgen-bio.svg" alt="Logo Amgen" />
        </AColumn>
      </AColumns>
    </div>
  </nav>
</template>

<script>
import $ from "jquery";
import { ref } from "vue";
import AColumn from "@/components/atoms/Column.vue";
import AColumns from "@/components/atoms/Columns.vue";
import ALogo from "@/components/atoms/Logo.vue";

export default {
  name: "MNav",
  components: {
    AColumn,
    AColumns,
    ALogo
    // MContact
  },
  setup() {
    const vuexData = localStorage.getItem("vuex");
    let isUserLoggedIn = false; // Defina o valor padrão como false

    if (vuexData) {
      // Analisar a string JSON para um objeto JavaScript
      const parsedVuexData = JSON.parse(vuexData);

      // Acessar informações específicas dentro do objeto, se disponíveis
      const userToken = parsedVuexData?.user?.auth?.token;
      const userName = parsedVuexData?.user?.data?.NomeConsumidor;
      isUserLoggedIn = parsedVuexData?.user?.isUserLoggedIn || false;

      console.log(userToken, userName, isUserLoggedIn);
    }

    const mobileOpen = ref(false);
    let host = `//${document.location.hostname}`;
    const isLocalhost = /localhost/g.test(host);

    if (isLocalhost) {
      host += ":8000";
    }

    function goToElement(id, fallback = false) {
      mobileOpen.value = false;

      let $target = $(id);

      if (!$target.length && fallback) {
        $target = $(fallback);
      }

      if ($target.length) {
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $target.offset().top - 80
          },
          1000
        );
      }
    }

    return {
      goToElement,
      mobileOpen,
      host,
      isUserLoggedIn
    };
  },
  methods: {
    toggleMobMenu() {
      this.mobileOpen = !this.mobileOpen;
    },
    logout() {
      localStorage.clear();
      localStorage.removeItem("vuex");
      this.isUserLoggedIn = false;
      this.redirectLogout();
    },
    redirectLogout() {
      window.location.href = `//${window.location.host}/login`;
    }
  }
};
</script>

<style lang="scss">
$m-nav: ".m-nav";

#{$m-nav} {
  max-width: 510px;

  &__mobile {
    backface-visibility: hidden;
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 9;

    &::before {
      @extend %pseudo-el;
      @extend %zero-position;
      background-color: black;
      display: block;
      height: 100%;
      opacity: 0;
      pointer-events: none;
      position: fixed;
      width: 100%;
      z-index: -1;
    }

    &::after {
      @extend %position-absolute;
      @extend %pseudo-el;
      @extend %translate-x-center;
      background-color: #1880c3;
      border-radius: 50%;
      bottom: -25vh;
      display: block;
      height: 36vh;
      transition: all 0.3s ease-in;
      width: 38vh;
      z-index: -1;
    }

    .amgen-logo {
      display: none;
    }

    &--open {
      height: 84vh;

      &::before {
        opacity: 0.5;
      }

      &::after {
        background-color: $lochmara;
        bottom: -8vh;
        height: 96vh;
        width: 200%;
      }

      & > .columns {
        height: 100%;
      }

      #{$m-nav}__mobile {
        &__container {
          background-color: transparent;
          border-radius: 0;
          box-shadow: none;
          height: 100%;
          padding: 4vh 0 0;
          text-align: inherit;
          width: 100%;

          img {
            max-width: 40%;
          }
        }

        &__action {
          @extend %translate-x-center;
          background-color: transparent;
          bottom: 42px;
          box-shadow: none;
          height: 30px;
          top: initial;
          width: 30px;

          img {
            &:nth-of-type(1) {
              opacity: 1;
            }

            &:nth-of-type(2) {
              opacity: 0;
            }
          }
        }
      }

      .amgen-logo {
        align-items: flex-end;
        display: flex;
        justify-content: center;
        margin-bottom: 12px;
      }
    }

    &__action {
      box-shadow: 0 6px 12px #00000029;
    }

    &__container {
      border-radius: 60px;
      color: white;
      height: 34px;
      width: 160px;

      img {
        max-width: 60%;
      }

      a {
        display: block;
      }

      .white-circle {
        @extend %white-circle;
        display: inline-block;
        height: 20px;
        width: 20px;
      }

      .m-contact {
        .m-section-title {
          margin-bottom: 0 !important;

          &__title {
            color: $white;
            font-weight: 300;
          }
        }

        &__phone {
          font-size: 1.3rem !important;
          letter-spacing: 4px;
        }
      }
    }

    &__action {
      @extend %position-absolute;
      @extend %white-circle;
      height: 40px;
      left: 50%;
      top: calc(-8vh - 6px);
      transform: translateX(-50%);
      width: 40px;

      img {
        &:nth-of-type(1),
        &:nth-of-type(2) {
          @extend %position-absolute;
          left: 50%;
          transform: translate(-50%, -50%);
          top: 50%;
        }

        &:nth-of-type(1) {
          opacity: 0;
        }

        &:nth-of-type(2) {
          opacity: 1;
          width: 38%;
        }
      }
    }

    &__items {
      justify-content: center !important;

      li {
        margin-bottom: 2vh !important;

        &:nth-last-of-type(1) {
          margin-left: 6px !important;
        }

        &:nth-last-of-type(2) {
          margin-right: 6px !important;
        }

        &:nth-last-of-type(1),
        &:nth-last-of-type(2) {
          a {
            font-size: pxToEm(11px);
            font-weight: 500;
            height: 26px;
            padding: 4px 0 0;

            &::before {
              display: none;
            }
          }
        }

        &:nth-of-type(1) {
          a {
            img {
              left: 8px;
              margin-top: -2px;
            }
          }
        }

        &:nth-of-type(2) {
          a {
            img {
              left: 8px;
              margin-top: 2px;
            }
          }
        }

        &:nth-of-type(3) {
          a {
            img {
              left: 8px;
            }
          }
        }

        &:nth-of-type(4) {
          a {
            img {
              left: 8px;
            }
          }
        }
      }

      a {
        background-color: transparent;
        border: 2px solid $white;
        border-radius: 60px;
        color: $white;
        font-size: pxToEm(12px);
        font-weight: 600;
        height: 40px;
        padding: 0.6rem 0 0 4em;
        position: relative;

        &::before {
          @extend %pseudo-el;
          @extend %position-absolute;
          @extend %white-circle;
          box-shadow: 0 6px 12px #00000048;
          display: block;
          height: 40px;
          left: -1px;
          top: -2px;
          width: 40px;
        }

        img {
          @extend %position-absolute;
          left: 12px;
          top: 50%;
          transform: translateY(-50%);
          max-width: 7% !important;
        }
      }
    }
  }

  &__desktop {
    &-wrapper {
      display: none;
      position: relative;
    }

    &__action {
      @extend %position-absolute;
      background-color: $white;
      border-radius: 60px 0 0 60px;
      right: 200px !important;
      top: 10px !important;
      width: 60px;

      img {
        margin-left: 4px;
        margin-top: 4px;

        &:nth-last-of-type(1) {
          display: none;
        }
      }
    }

    li {
      @extend %position-absolute;
      right: 16px;

      &:nth-of-type(1) {
        a,
        a::before {
          background-color: #008caa;
        }
      }

      &:nth-of-type(2) {
        a,
        a::before {
          background-color: #199bb9;
        }
      }

      &:nth-of-type(3) {
        a,
        a::before {
          background-color: #4bb2cc;
        }
      }

      &:nth-of-type(4) {
        a,
        a::before {
          background-color: #6dc2d8;
          z-index: 1;
        }

        a::after {
          @extend %position-absolute;
          @extend %pseudo-el;
          border-color: #fff;
          border-radius: 60px 0 0 60px;
          box-shadow: 0px 30px 45px #00000025;
          height: 100%;
          left: -24px;
          top: 0;
          width: calc(100% + 24px);
          z-index: 0;
        }
      }

      &:nth-of-type(5) {
        a {
          background-color: #79cde3;
          border-radius: 0;

          &::before {
            background-color: #79cde3;
          }

          &::after {
            @extend %position-absolute;
            @extend %pseudo-el;
            border-radius: 60px 0 0 60px;
            box-shadow: 0px 30px 45px #00000025;
            height: 100%;
            left: -24px;
            top: 0;
            width: calc(100% + 24px);
            z-index: 0;
          }

          img {
            margin-left: -18px;
          }
        }
      }

      &:not(:nth-last-of-type(1)) {
        right: 30px;

        a {
          border-radius: 0 60px 60px 0;
          font-size: 0;

          &::before {
            left: -40px;
          }
        }
      }

      &:nth-last-of-type(1) {
        z-index: 4;
      }

      a {
        color: $white;
        font-family: "Helvetica Neue Bold";
        font-size: pxToEm(11px);
        line-height: pxToEm(18px);
        max-height: 46px;
        min-height: 46px;
        position: relative;
        text-decoration: none;
        width: 100%;
        // box-shadow: 0px 12px 12px #00000031 !important;

        &::before {
          @extend %position-absolute;
          @extend %pseudo-el;
          border-radius: 60px 0 0 60px;
          height: 100%;
          left: -24px;
          top: 0;
          width: 34px;
          z-index: 2;
        }

        img,
        span {
          vertical-align: middle;
        }

        img {
          height: 18px;
          margin-left: -12px;
          margin-right: 6px;
          position: relative;
          z-index: 3;
        }
      }
    }

    &--open {
      & + #{$m-nav} {
        &__desktop {
          &__action {
            //ABERTO
            left: -65px;
            right: 0;
            top: 12px !important;

            img {
              &:nth-of-type(1) {
                display: none;
              }

              &:nth-last-of-type(1) {
                display: inline;
              }
            }
          }
        }
      }

      li {
        right: 0;
        position: relative;

        &:not(:nth-last-of-type(1)) {
          right: 0;

          a {
            border-radius: 0;
            font-size: pxToEm(12px);

            &::before {
              left: -24px;
            }
          }
        }
      }
    }

    @include media(">=desktop") {
      &__action {
        right: 158px;
        top: 5px;
      }

      li {
        a {
          font-size: pxToEm(12px);
          max-height: 60px;
          min-height: 60px;

          &::before {
            left: -30px;
          }

          img {
            height: 24px;
            margin-left: -10px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  @include media(">=tablet") {
    &__mobile {
      &__items {
        li {
          margin-bottom: 3vh !important;
        }

        a {
          height: 60px;
          font-size: pxToEm(14px);
          padding: 1.25rem 0 0 5rem;

          &::before {
            height: 60px;
            width: 60px;
          }
        }
      }
    }
  }

  @include media(">=desktop") {
    // max-width: 760px;
    max-width: 530px;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;

    &__mobile {
      display: none;
    }

    &__desktop {
      &-wrapper {
        display: block;
      }
    }
  }

  .bol {
    a {
      &::before {
        width: 0px;
      }
    }
  }

  .link-mobile {
    display: none !important;
  }

  .link-mobile-open {
    display: contents !important;
  }
}

.menu-espaco {
  margin-left: -290px;
}
</style>
