<template>
  <div class="container">
    <slot />
  </div>
</template>

<script>
export default {
  name: "AContainer"
};
</script>

<style lang="scss">
@import "~bulma/sass/elements/container.sass";
</style>
