<template>
  <div class="t-fale-conosco wrapper" id="wrapper">
    <OHeader />
    <OMain />
    <OFooter showContactBlock />
  </div>
</template>

<script>
import OHeader from "@/components/organisms/Header.vue";
import OFooter from "@/components/organisms/Footer.vue";
import OMain from "@/components/organisms/Main.vue";

export default {
  components: {
    OFooter,
    OHeader,
    OMain
  },
  name: "THome"
};
</script>

<style lang="scss">
body {
  padding: 0 !important;
  margin: 0 !important;
  // overflow-x: hidden !important
}

.t-fale-conosco {
  #header {
    background: none;
    position: absolute !important;

    .columns {
      margin-bottom: 0;
    }

    .a-logo-bottom {
      img {
        max-width: 300px;
        width: 64%;
      }
    }
  }

  .m-section-title__title {
    font-weight: 600;
  }

  .m-video {
    &::before {
      @extend %position-absolute;
      @extend %pseudo-el;
      background: rgb(41, 136, 195);
      background: radial-gradient(
        circle,
        rgba(41, 136, 195, 1) 0%,
        rgba(24, 100, 147, 1) 100%
      );
      display: block;
      height: calc(100% + 1px);
      opacity: 0.55;
      pointer-events: none;
      width: 100%;
      z-index: 2;
    }

    &.is-playing {
      &::before {
        display: none;
      }
    }
  }

  .o-fale-conosco {
    background-color: #0275bf;
    border-radius: 10px;
    color: $white;

    .play {
      @extend %position-absolute;
      height: 60px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 60px;
    }

    .m-video {
      box-shadow: 0 20px 30px #00000030;
    }
  }

  .m-video {
    margin: 0 auto;
  }
}
</style>
