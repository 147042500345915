<template>
  <div class="content">
    <slot />
  </div>
</template>

<script>
export default {
  name: "AContent"
};
</script>

<style lang="scss">
@import "~bulma/sass/elements/content.sass";
</style>
