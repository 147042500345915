<template>
  <ASection class="main-section pb-0 pl-0 pr-0" id="main-section">
    <main class="main" id="main">
      <OBenefits />
      <OMedicines />
      <PacienteArea />
      <ONews />
    </main>
  </ASection>
</template>

<script>
import ASection from "@/components/atoms/Section.vue";
import OBenefits from "@/components/organisms/Benefits.vue";
import OMedicines from "@/components/organisms/Medicines.vue";
import ONews from "@/components/organisms/News.vue";
import PacienteArea from "./PacienteArea.vue";

export default {
  components: {
    ASection,
    OBenefits,
    OMedicines,
    ONews,
    PacienteArea
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  name: "OMain"
};
</script>

<style lang="scss">
.main {
  margin-top: -96px;

  @include media(">=widescreen") {
    margin-top: -166px;
  }

  @include media(">=fullhd") {
    margin-top: -206px;
  }
}
</style>
