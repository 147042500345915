<template>
  <AContainer class="b-benefits pl-0 pr-0 pb-5">
    <div class="b-benefits__content">
      <div class="o-benefits has-text-centered is-flex">
        <!-- <ASectionTitle> -->
        <!-- <template v-slot:icon>
            <img class="is-hidden-tablet" src="../../assets/white-like.svg" alt="Ícone benefícios" />
            <img class="is-hidden-mobile" src="../../assets/white-like.svg" alt="Ícone benefícios BemPerto" />
          </template> -->
        <!-- <template v-slot:title><span style="color: #fff !important; font-weight: 400; font-size: 17px;"> Sobre o
              programa </span></template> -->
        <!-- </ASectionTitle> -->
        <div class="o-benefits__slogan">
          <p
            class="has-text-white has-text-weight-light is-size-5-mobile is-size-4-tablet is-size-3-widescreen p-0 m-0"
          >
            O que o
          </p>
          <img
            class="bempertoimg"
            src="../../assets/Bemperto_azul_branco.svg"
            alt="O que o BemPerto traz para você"
          />
          <p
            class="has-text-white has-text-weight-light is-size-5-mobile is-size-4-tablet is-size-3-widescreen p-0 m-0"
          >
            traz para você?
          </p>
        </div>
      </div>
      <div class="o-benefits__actions is-flex">
        <AButton class="o-benefits__action is-flex" @click="openModal">
          <img alt="Play Video" src="../../assets/video.svg" />
          <span>Conheça a Amgela</span>
        </AButton>
        <AButton
          class="o-benefits__action is-flex"
          component="link"
          to="/sobre-o-programa"
        >
          <img
            alt="Saiba Mais"
            src="../../assets/fechar-plus.svg"
            style="transform: rotate(134deg);"
          />
          <span>Saiba Mais</span>
        </AButton>
      </div>
    </div>
  </AContainer>
</template>

<script>
import EventBus from "@/EventBus";
import AButton from "@/components/atoms/Button.vue";
import AContainer from "@/components/atoms/Container.vue";
// import ASectionTitle from "@/components/molecules/SectionTitle.vue";

export default {
  components: {
    AButton,
    AContainer
  },
  name: "OBenefits",
  methods: {
    openModal() {
      EventBus.emit("openAppModal");
      console.log("openModal event triggered");
    }
  }
};
</script>

<style lang="scss">
.o-benefits {
  align-items: center;
  background-color: #70cded;
  border: 1px solid white;
  border-radius: 10px;
  height: 200px;
  justify-content: center;
  margin: 0 auto;
  max-width: calc(100% - 40px);
  // margin-top: 1rem;
  // padding: 2.4rem 1.8rem;
  position: relative;

  @include media(">=tablet") {
    height: 240px;
  }

  @include media(">=widescreen") {
    height: 280px;
    max-width: 1100px;
  }

  @include media(">=fullhd") {
    height: 330px;
  }

  &__slogan {
    margin-top: -12px;

    @include media(">=tablet") {
      margin-top: -20px;
    }
  }

  &__actions {
    @extend %position-absolute;
    align-items: center;
    bottom: 0;
    justify-content: center;
    left: 50%;
    transform: translate(-50%, 0);
    width: calc(100% - 40px);
    z-index: 2;

    @include media(">=tablet") {
      bottom: -24px;
    }
  }

  &__action {
    align-items: center;
    background-color: white !important;
    box-shadow: 0 12px 15px rgba(0, 0, 0, 0.05);
    color: #70cded !important;
    font-size: pxToEm(12px);
    font-weight: 700;
    min-width: 132px;
    padding: 10px;

    &:nth-of-type(1) {
      margin-right: 8px;
    }

    &:nth-of-type(2) {
      margin-left: 8px;
    }

    img {
      flex: none;
      margin-right: 5px;
      width: 30px;

      @include media(">=fullhd") {
        width: 40px;
      }
    }

    span {
      display: block;
      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 1;
      text-align: center;
    }

    @include media(">=tablet") {
      font-size: pxToEm(14px);
      min-width: 160px;

      &:nth-of-type(1) {
        margin-right: 12px;
      }

      &:nth-of-type(2) {
        margin-left: 12px;
      }
    }

    @include media(">=widescreen") {
      font-size: pxToEm(16px);
      min-width: 190px;

      &:nth-of-type(1) {
        margin-right: 1.2rem;
      }

      &:nth-of-type(2) {
        margin-left: 1.2rem;
      }
    }

    @include media(">=fullhd") {
      font-size: pxToEm(18px);
      min-width: 220px;
      padding: 12px;

      &:nth-of-type(1) {
        margin-right: 1.4rem;
      }

      &:nth-of-type(2) {
        margin-left: 1.4rem;
      }
    }
  }
}

.bempertoimg {
  margin-top: -14px;
  max-width: 72%;

  @include media(">=tablet") {
    max-width: 82%;
  }

  @include media(">=widescreen") {
    max-width: 94%;
  }

  @include media(">=fullhd") {
    max-width: 100%;
  }
}

@include media(">=tablet") {
  .b-benefits {
    // background-image: url("../../assets/blue-wave.svg");
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
    min-height: 20vh;
    // padding-top: calc(42% + 10px);

    &__content {
      left: 50%;
      max-width: 1005px;
      position: absolute;
      transform: translate(-50%, -50%);
      top: calc(50% - 16px);
      width: 86%;
    }
  }
}
</style>
