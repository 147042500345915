<template>
  <span>
    <img
      alt="Logo BemPerto e cuidando de você"
      src="../../assets/logo-bemperto-cuidando-de-voce.svg"
      v-if="logoType === 'default'"
    />
    <img
      alt="Logo BemPerto"
      src="../../assets/logo-bemperto.svg"
      v-if="logoType === 'simple'"
    />
  </span>
</template>

<script>
export default {
  name: "ALogo",
  props: {
    logoType: {
      default: "default",
      required: false,
      type: String
    }
  }
};
</script>
