<template>
  <button v-if="component === 'button'" class="a-button has-text-centered">
    <slot />
  </button>
  <a v-else-if="component === 'external'" class="a-button has-text-centered">
    <slot />
  </a>
  <router-link v-else class="a-button has-text-centered">
    <slot />
  </router-link>
</template>

<script>
export default {
  name: "AButton",
  props: {
    component: {
      default: "button",
      required: false,
      type: String
    }
  }
};
</script>

<style lang="scss">
.a-button {
  @extend %transition-default;
  background: none;
  background-color: $denim;
  border: 0;
  border-radius: 60px;
  box-shadow: 0 6px 14px #00000030;
  color: $white;
  cursor: pointer;
  font-size: pxToEm(14px);
  min-width: 202px;
  outline: none;

  &:hover {
    background-color: $white;
    box-shadow: 0 6px 14px #1880c330;
    color: $denim;
  }
}
</style>
