<template>
  <AColumns
    class="m-section-title has-text-centered is-centered is-gapless is-mobile is-multiline"
  >
    <AColumn class="m-section-title__icon is-full">
      <slot name="icon" />
    </AColumn>
    <AColumn class="m-section-title__title is-full is-size-7 is-uppercase mt-1">
      <span>
        <slot name="title" />
      </span>
    </AColumn>
  </AColumns>
</template>

<script>
import AColumn from "@/components/atoms/Column.vue";
import AColumns from "@/components/atoms/Columns.vue";

export default {
  name: "ASectionTitle",
  components: {
    AColumn,
    AColumns
  }
};
</script>

<style lang="scss">
.m-section-title {
  margin-bottom: 1rem !important;

  &__title {
    color: #0275bf;
    font-weight: 500;
    letter-spacing: 4.8px;
    margin-bottom: 6px;

    span {
      display: inline-block;
      position: relative;
      transform: translateX(5px);
    }
  }

  @include media(">=tablet") {
    margin-bottom: 0 !important;

    &__title {
      margin-bottom: 12px !important;
    }
  }
}
</style>
