<template>
  <AContainer class="b-news is-fluid pl-0 pr-0 container-area">
    <div class="card-area">
      <div class="card-area__card">
        <span class="text-area">
          Se você já faz parte do<br />
          BemPerto<sup>®</sup>, clique abaixo para<br />
          acessar a sua conta e ter<br />
          acesso aos benefícios.
        </span>
        <div class="mt-3">
          <AButton
            class="button-area"
            v-on:click="_goToLogin()"
            style="display: flex; align-items: center;"
          >
            <img
              src="../../assets/enter-azul.svg"
              style="width: 30px; margin-right: 35px; margin-left: 10px;"
            />
            Entre
          </AButton>
        </div>
      </div>
      <div class="card-area__card">
        <span class="text-area">
          Faça parte do <br />
          Programa BemPerto<sup>®</sup> <br />
          e tenha acesso a conteúdos exclusivos <br />
          e benefícios imperdíveis.
        </span>
        <div class="mt-3">
          <AButton
            class="button-area"
            v-on:click="_goToRegister()"
            style="display: flex; align-items: center;"
          >
            <img
              src="../../assets/userplus.svg"
              style="width: 30px; margin-right: 20px; margin-left: 10px;"
            />
            Cadastre-se
          </AButton>
        </div>
      </div>
    </div>
  </AContainer>
</template>

<script>
import AContainer from "@/components/atoms/Container.vue";
import AButton from "@/components/atoms/Button.vue";

export default {
  components: {
    AButton,
    AContainer
  },
  name: "PacienteArea",
  methods: {
    _goToLogin() {
      return (window.location.href =
        "//" + window.location.host + "/" + "login");
    },

    _goToRegister() {
      return (window.location.href =
        "//" + window.location.host + "/" + "cadastro-novo");
    }
  }
};
</script>

<style lang="scss">
.b-news {
  margin-top: 2rem;

  @include media(">=desktop") {
    margin-top: 6rem;
  }

  @include media(">=fullhd") {
    margin-top: 12rem;
  }
}

.container-area {
  padding: 2rem 1rem;
  background-image: linear-gradient(
    to bottom,
    #c299ff,
    #ab9ffe,
    #78bbef,
    #54d6fc
  );

  @include media(">=tablet") {
    background-image: linear-gradient(
      to right,
      #c299ff,
      #ab9ffe,
      #78bbef,
      #54d6fc
    );
  }

  @include media(">=fullhd") {
    padding: 4rem 0;
  }
}

.text-area {
  color: #fff;
  font-size: pxToEm(15px);
  margin-bottom: 5px;

  @include media(">=fullhd") {
    font-size: pxToEm(18px);
    line-height: pxToEm(22px);
  }
}

.card-area {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

  &__card {
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  @include media(">=tablet") {
    flex-direction: row;

    &__card {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  @include media(">=fullhd") {
    justify-content: center;

    &__card {
      &:nth-of-type(1) {
        margin-right: 4rem;
      }

      &:nth-of-type(2) {
        margin-left: 4rem;
      }
    }
  }
}

.button-area {
  background: none;
  background-color: white;
  border: 0;
  border-radius: 60px;
  box-shadow: 0 6px 14px #00000030;
  color: #78bbef;
  cursor: pointer;
  font-size: pxToEm(17px);
  font-weight: 600;
  margin: 0 auto;
  min-width: 202px;
  height: 50px;
  outline: none;

  &:hover {
    background-color: $white;
    box-shadow: 0 6px 14px #1880c330;
    color: $denim;
  }
}
</style>
