import { createRouter, createWebHistory } from "vue-router";
import PHome from "@/components/pages/Home.vue";
const PAbout = () => import("@/components/pages/About.vue");
const PFaleConosco = () => import("@/components/pages/FaleConosco.vue");

const routes = [
  {
    path: "/",
    name: "Home",
    component: PHome
  },
  {
    path: "/sobre-o-programa",
    name: "About",
    component: PAbout
  },
  {
    path: "/fale-conosco",
    name: "FaleConosco",
    component: PFaleConosco
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    // console.log('aquiiii teste');
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth"
      };
    } else {
      return {
        top: 0
      };
    }
  }
});

export default router;
