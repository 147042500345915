<template>
  <router-view></router-view>
</template>

<script>
import { onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import $ from "jquery";

export default {
  name: "App",
  setup() {
    const route = useRoute();
    let pageBodyClassPrefix = "page-";

    onMounted(() => {
      const cookieScript = document.createElement("script");
      // if does not exist, create the script tag
      cookieScript.id = "Cookiebot";
      cookieScript.src = "https://consent.cookiebot.com/uc.js";
      cookieScript.setAttribute(
        "data-cbid",
        "9d206699-ef1a-4540-b045-9108a6c1823b"
      );
      cookieScript.setAttribute("data-blockingmode", "auto");
      cookieScript.type = "text/javascript";
      if (!document.getElementById("Cookiebot")) {
        document.head.appendChild(cookieScript);
      }
    });

    watch(
      () => route.path,
      async path => {
        const page = path.replace("/", "");
        let pageBodyClass = pageBodyClassPrefix;

        if (!page) {
          pageBodyClass += "home";
        } else {
          pageBodyClass += page;
        }

        $("body")
          .removeClass((index, css) =>
            (css.match(/(^|\s)page-\S+/g) || []).join(" ")
          )
          .addClass(pageBodyClass);
      }
    );
  }
};
</script>
