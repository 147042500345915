<template>
  <component class="column" :is="tag">
    <slot />
  </component>
</template>

<script>
export default {
  name: "AColumn",
  props: {
    tag: {
      default: "div",
      required: false,
      type: String
    }
  }
};
</script>
